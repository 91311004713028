<template>
  <b-col class="px-0 border-left-light border-left-line cart__product--container"
    :class="$store.state.cashier.totalBuy > 0 ? 'bg-gray-3' : 'bg-gray-2'" cols="12" md="4" lg="4">
    <b-row class="mx-0 border-bottom-light border-bottom-line align-items-center"
      :class="totalBuy > 0 ? 'bg-gray-3' : 'bg-gray-2'" style="height: 80.78px">
      <b-col class="" cols="5" md="7" lg="6">
        <div class="d-flex" style="gap: 0.5rem">
          <b-button variant="primary" @click="$router.push({ name: 'welcome.index' })">
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button variant="warning" @click="$router.push({ name: 'cart.index' })">
            <feather-icon icon="ShoppingCartIcon" />
          </b-button>
        </div>
      </b-col>
      <b-col class="align-self-center pr-0" cols="7" md="5" lg="6">
        <div class="d-flex" :class="{ 'justify-content-between': totalBuy > 0 }">
          <h1 class="h5 m-0 text-dark">
            Total Pembelian: {{ totalBuy }}
          </h1>
        </div>
      </b-col>
    </b-row>
    <div class="">
      <div class="cart--scrollbar">
        <SelectedCustomer />
        <CartListProduct />
      </div>
      <GroupButtonCartCashier :is_discount="true" :is_process="false" :deleteCart="deleteCart" />
    </div>

    <!-- <div class="vertical-center" v-if="$store.state.cart.totalBuy == 0">
      <EmptyListProduct :title="'Pilih barang untuk dimasukan keranjang'"
        :sub="'Bisa pilih melalui etalase atau favorit'" />
    </div> -->
    <ModalSelectCustomer />
  </b-col>
</template>

<script>
import {
  BCol, BRow, BButton,
} from 'bootstrap-vue'

import { ValidationObserver } from 'vee-validate'

import ModalSelectCustomer from '@/components/Pos2/Modal/SelectCustomer.vue'
import EmptyListProduct from '@/components/Pos2/EmptyListProduct.vue'
import GroupButtonCartCashier from '@/components/Pos2/GroupButtonCartCashier.vue'
import CartListProduct from '@/components/Pos2/Cart/CartListProduct.vue'
import SelectCustomer from '@/components/Pos2/Card/SelectCustomer.vue'
import SelectedCustomer from '@/components/Pos2/Card/SelectedCustomer.vue'

export default {
  components: {
    ValidationObserver,
    BCol,
    EmptyListProduct,
    GroupButtonCartCashier,
    CartListProduct,
    ModalSelectCustomer,
    BRow,
    BButton,
    SelectCustomer,
    SelectedCustomer,
  },
  data() {
    return {
      totalBuy: this.$store.state.cart.totalBuy,
    }
  },
  watch: {
    '$store.state.cart.totalBuy': function (value) {
      const vm = this
      vm.totalBuy = value
    },
  },
  methods: {
    deleteCart() {
      this.$swal({
        title: 'Apakah kamu yakin?',
        text: 'Apakah kamu yakin menghapus semua produk di keranjang?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('cart/deleteCart', {
            uuid: this.$store.state.cart.cartUuid || '',
          }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Sukses!',
              text: 'Produk berhasil dihapus',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            // this.$router.replace({ name: 'pos.index' })
            this.getActiveCart()
          }).catch(err => {
            // eslint-disable-next-line no-console
            console.log(err)
          })
        }
      })
    },
    async getActiveCart() {
      await this.$store.dispatch('cart/getCart', {
        uuid: this.$route.query.uuid || '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/setDiscountCart', item.discount_value)
        this.$store.commit('cart/setDiscountType', item.discount_type)
        this.$store.commit('cart/setDiscountTotal', item.discount_total)
        this.$store.commit('cart/setSubtotalCart', item.subtotal)
        this.$store.commit('cart/setTotalPrice', item.total)
        this.$store.commit('cart/setTotalBuy', item.items.length)
        this.$store.commit('cart/setCartUuid', item.uuid)
        this.$store.commit('cart/setResultCartActive', item)
        this.$store.commit('cashier/setProduct', item.items.length)
        localStorage.setItem('cash_total_payment', item.total)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

// @import '@/variables/_variables.scss';
/* =============== CART PRODUCT =============== */
.cart__product--container {

  .cart--scrollbar {
    height: calc(100vh - 231px);
    overflow-y: auto;

    // &::-webkit-scrollbar {
    //   width: 6px;
    //   height: 6px;
    //   background-color: #fff;
    //   border-radius: 4px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #aaa;
    //   border-radius: 4px;
    // }
  }

  .wrap--cart {
    // height: calc(100vh - 81px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // height: 100vh;
  .floating__button {
    position: sticky;
    width: 100%;
    bottom: 0;
  }

  .scroll__cart {
    overflow-y: scroll;
    height: 73vh;
  }

  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }

  .card.card__discount {
    background: #F1F9F8;
    border-radius: 16px 16px 0px 0px;
    box-shadow: none;
  }

  .card.card__click:hover {
    cursor: pointer;
  }

  .b-avatar.badge-light-warning.custom-avatar {
    background-color: #FEF9F5;
    color: #E79242;
  }

  .media-list {

    .media {
      position: relative;

      .media-aside-right {
        margin-left: 0px !important;

        >div {
          width: 134px;
        }
      }

      .media-body {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        height: 18vh;
        justify-content: space-between;
      }

      &:not(:last-child)::after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: -3vh;
        border-bottom: 1px solid #E4E5EC;
      }

      .floating__container {
        position: relative;

        .floating__close--button {
          position: absolute;
          top: 6px;
          width: 24px;
          height: 24px;
          right: 6px;
          background: #fff;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .media__image {
        width: 100%;
        height: 85px;
        object-fit: cover;
        border-radius: 8px;
      }

      .spinner__button {
        width: 100%;
        display: flex;
        padding: 0.5rem;
        background: $light--1;
        border-radius: 8px;

        #input-wrap {
          margin: 0px;
          padding: 0px;
        }

        .value-button {
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid #ddd;
          margin: 0px;
          width: 65px;
          text-align: center;
          vertical-align: middle;
          background: #fff;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          border-radius: 6px;
        }

        .value-button:hover {
          cursor: pointer;
        }

        input.qty {
          text-align: center;
          border: none;
          margin: 0px;
          width: 100%;
          background: transparent;
          height: 32px;

          &:focus {
            border: none;
            outline: none;
          }
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
</style>
