<template>
  <div class="d-flex justify-content-center align-items-center">
    <div class="text-center">
      <b-img
        :src="emptyCart"
        width="135"
      />
      <h1 class="h5 text-dark fw-bold-800 size14 mt-2">
        <!-- Pilih barang untuk dimasukan keranjang -->
        {{ title }}
      </h1>
      <small class="text-dark text-darken-4">
        <!-- Bisa pilih melalui etalase atau favorit -->
        {{ sub }}
      </small>
    </div>
  </div>
</template>

<script>
import emptyCart from '@/assets/images/empty-cart.png'

import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'sub'],
  data() {
    return {
      emptyCart,
    }
  },
}
</script>
