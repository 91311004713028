<template>
  <b-card class="mb-0 card__click mx-2 mt-1" v-b-modal.modal-select-customer>
    <b-card-body class="d-flex flex-row align-items-center justify-content-between p-0">
      <div class="d-flex align-items-center">
        <b-avatar
          variant="light-warning"
          size="35"
          class="custom-avatar mr-1"
        />
        <h1 class="h5 mb-0 text-dark font-weight-bold">
          Masukkan pelanggan (opsional)
        </h1>
      </div>
      <feather-icon
        class="text-dark text-darken-4"
        size="24"
        icon="ChevronRightIcon"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
}
</script>
